import { GetState, SetState } from 'zustand'
import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { STRINGS } from 'references/locale'
import { getCustomerAccessToken, setCustomerAccessToken } from 'helpers/tokens'

import { updateCustomer } from 'services/customer/updateCustomer'
import {
  updateCustomerMetafields,
  CustomerMetafields,
} from 'services/customer/updateCustomerMetafields'

import { CustomerStateWithAccountState } from './index'

export type UpdateCustomerPayload = Partial<
  Pick<Shopify.CustomerUpdateMutationVariables, 'customer'>
> & {
  metafields?: CustomerMetafields
}

export type UpdateCustomerReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
}

export const customerUpdate =
  <TState extends CustomerStateWithAccountState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async ({
    customer,
    metafields,
  }: UpdateCustomerPayload): Promise<UpdateCustomerReturn> => {
    const accessToken = getCustomerAccessToken()
    const getCustomer = get().getCustomer

    /**
     * If there's an access token try to renew it
     * then try and get the customer after replacing
     * the existing token with the renewed.
     */
    if (accessToken) {
      if (metafields) {
        await updateCustomerMetafields(metafields, accessToken.accessToken)
      }

      /**
       * We dont have the customer object so we can't update it
       * so we just re-fetch the customer to make sure the data
       * is up to date. This is helpful if we're just updating
       * a metafield
       */
      if (!customer) {
        await getCustomer()

        return {
          success: true,
        }
      }

      const result = await updateCustomer({
        customerAccessToken: accessToken.accessToken,
        customer,
      })

      if (result.data?.customerUpdate?.customer) {
        const newToken = result.data.customerUpdate?.customerAccessToken

        if (newToken) {
          setCustomerAccessToken(newToken)
        }

        set({
          customer: result.data.customerUpdate.customer,
        })

        return {
          success: true,
        }
      } else if (result.data?.customerUpdate?.customerUserErrors) {
        return {
          success: false,
          customerErrors: result.data.customerUpdate.customerUserErrors.reduce(
            (acc, { field, message }) => {
              if (Array.isArray(field)) {
                const [_, fieldName] = field

                acc[fieldName] = message
              }

              return acc
            },
            {} as Record<string, string>
          ),
        }
      } else {
        const ERR_MSG = STRINGS['customer.update.sentry']
        console.error(ERR_MSG)
        captureException(ERR_MSG, () =>
          new Scope().setExtras({
            errors: result.errors,
            customer,
          })
        )

        return {
          success: false,
          error: STRINGS['customer.update.form.fail'],
        }
      }
    }

    /**
     * If no token then the user isn't logged in so no worries.
     */
    return { success: false }
  }
