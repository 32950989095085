import { captureException } from '@sentry/nextjs'
import { SetState, GetState } from 'zustand'

import { Google, Shopify } from '@cellulargoods/types'
import { createSanityClientRead } from '@cellulargoods/core'

import { replaceLineItems } from 'services/cart/replaceLineItems'

import { getLineItemInputs } from 'helpers/shopify'
import {
  createCartEvent,
  createProductFieldObjectFromLineItem,
  pushToDataLayer,
} from 'helpers/gtm'

import type { CheckoutStateWithCartState } from './index'

export type LineItemRemovePayload = {
  productVariantId: string
  keepCartClose?: boolean
}

export const lineItemRemove =
  <TState extends CheckoutStateWithCartState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async ({
    productVariantId,
    keepCartClose = false,
  }: LineItemRemovePayload) => {
    set({
      checkoutUpdating: true,
      lineItemMutating: {
        status: true,
        id: productVariantId,
      },
    })

    // Get existing checkout from store
    const checkout = get().checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItemInputs = getLineItemInputs(
      checkout?.lineItems?.edges?.map((edge) => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      const ERROR_MESSAGE = `Unable to retrieve checkout`
      console.error(ERROR_MESSAGE)
      captureException(ERROR_MESSAGE)
      return
    }

    // Remove line items with matching variant ID
    const filteredLineItems = lineItemInputs.filter(
      (lineItem) => lineItem.variantId !== productVariantId
    )

    const result = await replaceLineItems({
      checkoutId,
      lineItems: filteredLineItems,
    })

    if (result.errors) {
      const message = `Unable to retrieve checkout: ${result.errors
        .map((err) => err.message)
        .join(',')}`
      console.error(message)

      captureException(message)
      return
    }

    const updatedCheckout = result.data?.checkoutLineItemsReplace?.checkout

    // Get removed line item
    const removedLineItem = checkout?.lineItems?.edges.find(
      (edge) => edge.node.variant?.id === productVariantId
    )?.node

    /**
     * create sanity read client
     */
    const client = createSanityClientRead()

    /**
     * get the product type from shopify
     */
    const productType = await client.fetch(
      /* groq */ `*[_type == "shopifyProduct" && idEncoded == $id][0].productType`,
      {
        id: removedLineItem?.variant?.product?.id,
      }
    )

    /**
     * create the remove from cart event
     */
    const removeToCartProductDetail = createCartEvent(
      Google.CartEvents.REMOVE_FROM_CART,
      createProductFieldObjectFromLineItem(
        removedLineItem as Shopify.CheckoutLineItem,
        productType,
        productVariantId
      )
    )

    pushToDataLayer({ ecommerce: null })
    pushToDataLayer(removeToCartProductDetail)

    set({
      checkout: updatedCheckout as Shopify.Checkout,
      lineItemMutating: {
        status: false,
        id: null,
      },
      cartIsOpen: !keepCartClose,
    })
  }
