import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const loginCustomer = async (
  variables: Shopify.CustomerAccessTokenCreateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAccessTokenCreateMutation,
    Shopify.CustomerAccessTokenCreateMutationVariables
  >({
    mutation: gql`
      mutation customerAccessTokenCreate(
        $input: CustomerAccessTokenCreateInput!
      ) {
        customerAccessTokenCreate(input: $input) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
