import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const getOrders = async (
  variables: Shopify.GetCustomerOrdersQueryVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.query<
    Shopify.GetCustomerOrdersQuery,
    Shopify.GetCustomerOrdersQueryVariables
  >({
    query: gql`
      query getCustomerOrders($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          id
          orders(first: 100) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                orderNumber
                processedAt
                statusUrl
                fulfillmentStatus
                shippingAddress {
                  address1
                  address2
                  city
                  country
                  zip
                }
                subtotalPriceV2 {
                  amount
                }
                totalPriceV2 {
                  amount
                }
                totalShippingPriceV2 {
                  amount
                }
                totalTaxV2 {
                  amount
                }
                lineItems(first: 100) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    node {
                      title
                      quantity
                      variant {
                        product {
                          id
                        }
                        id
                        title
                        image {
                          id
                          transformedSrc
                        }
                        sku
                        priceV2 {
                          amount
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables,
  })

  return result
}
