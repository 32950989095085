import { Shopify } from '@cellulargoods/types'
import { GetState, SetState } from 'zustand'
import { AccountState } from '../accounts'
import {
  CreateCustomerAddressPayload,
  CreateCustomerAddressReturn,
  customerAddressCreate,
} from './createCustomerAddress'
import {
  customerAddressDelete,
  DeleteCustomerAddressPayload,
  DeleteCustomerAddressReturn,
} from './deleteCustomerAddress'
import { customerGet, CustomerGetReturn } from './getCustomer'
import { orderGet, OrderGetPayload, OrderGetReturn } from './getOrder'
import { ordersGet, OrdersGetReturn } from './getOrders'
import {
  customerUpdate,
  UpdateCustomerPayload,
  UpdateCustomerReturn,
} from './updateCustomer'
import {
  customerAddressUpdate,
  UpdateCustomerAddressPayload,
  UpdateCustomerAddressReturn,
} from './updateCustomerAddress'

export type CustomerState = {
  customer: null | Shopify.CoreCustomerFieldsFragmentFragment
  getCustomer: () => Promise<CustomerGetReturn>
  getOrders: () => Promise<OrdersGetReturn>
  getOrder: (payload: OrderGetPayload) => Promise<OrderGetReturn>
  createCustomerAddress: (
    payload: CreateCustomerAddressPayload,
    shouldBeDefault?: boolean
  ) => Promise<CreateCustomerAddressReturn>
  updateCustomerAddress: (
    payload: UpdateCustomerAddressPayload,
    shouldBeDefault?: boolean
  ) => Promise<UpdateCustomerAddressReturn>
  deleteCustomerAddress: (
    payload: DeleteCustomerAddressPayload
  ) => Promise<DeleteCustomerAddressReturn>
  updateCustomer: (
    payload: UpdateCustomerPayload
  ) => Promise<UpdateCustomerReturn>
}

export type CustomerStateWithAccountState = CustomerState & AccountState

export const createCustomerState = <
  TState extends CustomerStateWithAccountState
>(
  set: SetState<TState>,
  get: GetState<TState>
): CustomerState => ({
  customer: null,
  getCustomer: customerGet(set, get),
  getOrders: ordersGet(),
  getOrder: orderGet(),
  createCustomerAddress: customerAddressCreate(set, get),
  updateCustomerAddress: customerAddressUpdate(set, get),
  deleteCustomerAddress: customerAddressDelete(set, get),
  updateCustomer: customerUpdate(set, get),
})
