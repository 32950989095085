import { SetState } from 'zustand'
import {
  ActiveCustomerPayload,
  customerActivate,
  ActivateCustomerReturn,
} from './activateCustomer'
import {
  customerResetPassword,
  ResetCustomerPasswordPayload,
  ResetCustomerPasswordReturn,
} from './resetCustomerPassword'

import {
  customerCreate,
  CreateCustomerPayload,
  CreateCustomerReturn,
} from './createCustomer'
import {
  customerLogin,
  LoginCustomerPayload,
  LoginCustomerReturn,
} from './loginCustomer'
import { customerLogout, LogoutCustomerReturn } from './logoutCustomer'
import {
  customerRecover,
  RecoverCustomerPayload,
  RecoverCustomerReturn,
} from './recoverCustomer'
import { CustomerState } from '../customer'

export type AccountStateWithCustomerState = AccountState & CustomerState

export type AccountState = {
  customerLoggedIn: boolean
  setCustomerLoggedIn: (isLoggedIn: boolean) => void
  activateCustomer: (
    payload: ActiveCustomerPayload
  ) => Promise<ActivateCustomerReturn>
  createCustomer: (
    payload: CreateCustomerPayload
  ) => Promise<CreateCustomerReturn>
  loginCustomer: (payload: LoginCustomerPayload) => Promise<LoginCustomerReturn>
  recoverCustomer: (
    payload: RecoverCustomerPayload
  ) => Promise<RecoverCustomerReturn>
  logoutCustomer: () => Promise<LogoutCustomerReturn>
  resetCustomerPassword: (
    payload: ResetCustomerPasswordPayload
  ) => Promise<ResetCustomerPasswordReturn>
}

export const createAccountState = <
  TState extends AccountStateWithCustomerState
>(
  set: SetState<TState>
): AccountState => ({
  customerLoggedIn: false,
  setCustomerLoggedIn: (isLoggedIn: boolean) =>
    set({
      customerLoggedIn: isLoggedIn,
    }),
  activateCustomer: customerActivate(set),
  createCustomer: customerCreate(),
  loginCustomer: customerLogin(set),
  recoverCustomer: customerRecover(),
  logoutCustomer: customerLogout(set),
  resetCustomerPassword: customerResetPassword(),
})
