import { SetState } from 'zustand'

import {
  getCustomerAccessToken,
  revokeCustomerAccessToken,
} from 'helpers/tokens'

import type { AccountState } from './index'
import { logoutCustomer } from 'services/accounts/logoutCustomer'
import { STRINGS } from 'references/locale'
import { captureException } from '@sentry/minimal'
import { Scope } from '@sentry/hub'

export type LogoutCustomerReturn = {
  success: boolean
}

export const customerLogout =
  <TState extends AccountState>(set: SetState<TState>) =>
  async (): Promise<LogoutCustomerReturn> => {
    const accessToken = getCustomerAccessToken()

    if (accessToken) {
      /**
       * destroy the access token
       */
      const result = await logoutCustomer({
        customerAccessToken: accessToken.accessToken,
      })

      /**
       * delete from local storage
       */
      revokeCustomerAccessToken()

      if (!result.data) {
        const ERROR_MSG = STRINGS['customer.logout.sentry']
        console.error(ERROR_MSG)
        captureException(
          ERROR_MSG,
          new Scope().setExtras({
            errors: result.errors,
          })
        )
      }
    }

    /**
     * No matter what happens we'll set the state
     */
    set({
      customerLoggedIn: false,
    })

    return { success: true }
  }
