import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const activateCustomer = async (
  variables: Shopify.CustomerActivateByUrlMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerActivateByUrlMutation,
    Shopify.CustomerActivateByUrlMutationVariables
  >({
    mutation: gql`
      mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
        customerActivateByUrl(
          activationUrl: $activationUrl
          password: $password
        ) {
          customer {
            id
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
