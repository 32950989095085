import { captureException, Scope } from '@sentry/nextjs'
import { SetState } from 'zustand'

import { setCustomerAccessToken } from 'helpers/tokens'

import { STRINGS } from 'references/locale'

import { activateCustomer } from 'services/accounts/activateCustomer'

import { AccountState } from './index'

export type ActiveCustomerPayload = {
  activationUrl: string
  password: string
}

export type ActivateCustomerReturn = {
  success: boolean
  error?: string
  customerErrors?: {
    password?: string
  }
}

export const customerActivate =
  <TState extends AccountState>(set: SetState<TState>) =>
  async ({
    activationUrl,
    password,
  }: ActiveCustomerPayload): Promise<ActivateCustomerReturn> => {
    if (!activationUrl || !password) {
      console.error(STRINGS['customer.activate.payload.missing'])
      return {
        success: false,
        error: STRINGS['customer.activate.payload.missing'],
      }
    }

    const result = await activateCustomer({
      activationUrl,
      password,
    })

    if (result.data?.customerActivateByUrl?.customerAccessToken) {
      setCustomerAccessToken(
        result.data.customerActivateByUrl.customerAccessToken
      )

      set({
        customerLoggedIn: true,
      })

      return { success: true }
    } else if (result.data?.customerActivateByUrl?.customerUserErrors) {
      /**
       * Customer User Errors can be an array, but because there's
       * only one input it MUST be password
       */
      return {
        success: false,
        customerErrors: {
          password:
            result.data?.customerActivateByUrl?.customerUserErrors[0].message,
        },
      }
    } else {
      const ERROR_MSG = STRINGS['customer.activate.sentry']
      console.error(ERROR_MSG)
      captureException(
        ERROR_MSG,
        new Scope().setExtras({
          errors: result.errors,
          url: activationUrl,
        })
      )

      return {
        success: false,
        error: STRINGS['customer.activate.form.failed'],
      }
    }
  }
