import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

export const updateDefaultCustomerAddress = async (
  variables: Shopify.CustomerDefaultAddressUpdateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerDefaultAddressUpdateMutation,
    Shopify.CustomerDefaultAddressUpdateMutationVariables
  >({
    mutation: gql`
      mutation customerDefaultAddressUpdate(
        $customerAccessToken: String!
        $addressId: ID!
      ) {
        customerDefaultAddressUpdate(
          customerAccessToken: $customerAccessToken
          addressId: $addressId
        ) {
          customer {
            id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
