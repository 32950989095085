import { SetState } from 'zustand'

export type LocalCartState = {
  cartIsOpen: boolean
  setCartIsOpen: (isOpen: boolean) => void
}

export const createLocalCartState = <TState extends LocalCartState>(
  set: SetState<TState>
): LocalCartState => ({
  cartIsOpen: false,
  setCartIsOpen: (bool) =>
    set({
      cartIsOpen: bool,
    }),
})
