import { SetState } from 'zustand'

export type GorgiasState = {
  gorgiasActive: boolean
  setGorgiasActive: (isActive: boolean) => void
}

export const createGorgiasState = <TState extends GorgiasState>(
  set: SetState<TState>
): GorgiasState => ({
  gorgiasActive: false,
  setGorgiasActive: (bool) => set({ gorgiasActive: bool }),
})
