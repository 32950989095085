import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { CheckoutState, createCartState } from './cart'
import { createGorgiasState, GorgiasState } from './gorgias'
import { LocalCartState, createLocalCartState } from './localCart'
import { SearchState, createSearchState } from './search'
import { AccountState, createAccountState } from './accounts'
import { createCustomerState, CustomerState } from './customer'

export type UseStore = GorgiasState &
  SearchState &
  LocalCartState &
  CheckoutState &
  AccountState &
  CustomerState

/**
 * TO DO – move header context here
 */
export const useStore = create<UseStore>(
  devtools(
    (set, get) => ({
      ...createGorgiasState(set),
      ...createSearchState(set),
      ...createLocalCartState(set),
      ...createCartState(set, get),
      ...createAccountState(set),
      ...createCustomerState(set, get),
    }),
    'CellularGoods'
  )
)
