import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

import { CORE_CUSTOMER_FIELDS_FRAGMENT } from 'graph/shopify-storefront/customer'

export const getCustomer = async (
  variables: Shopify.GetCurrentCustomerQueryVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.query<
    Shopify.GetCurrentCustomerQuery,
    Shopify.GetCurrentCustomerQueryVariables
  >({
    query: gql`
      ${CORE_CUSTOMER_FIELDS_FRAGMENT}

      query getCurrentCustomer($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          ...CoreCustomerFieldsFragment
        }
      }
    `,
    variables,
  })

  return result
}
