import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

import { CHECKOUT_FRAGMENT } from 'graph/shopify-storefront/checkout'

export const getCheckout = async (checkoutId: string) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.query<Shopify.ShopifyCheckoutQuery>({
    query: gql`
      ${CHECKOUT_FRAGMENT}

      query ShopifyCheckout($checkoutId: ID!) {
        node(id: $checkoutId) {
          ... on Checkout {
            ...checkout
          }
        }
      }
    `,
    variables: {
      checkoutId,
    },
  })

  return result
}
