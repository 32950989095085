import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

import { ADDRESS_FRAGMENT } from 'graph/shopify-storefront/address'

export const createCustomerAddress = async (
  variables: Shopify.CustomerAddressCreateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAddressCreateMutation,
    Shopify.CustomerAddressCreateMutationVariables
  >({
    mutation: gql`
      ${ADDRESS_FRAGMENT}

      mutation customerAddressCreate(
        $customerAccessToken: String!
        $address: MailingAddressInput!
      ) {
        customerAddressCreate(
          customerAccessToken: $customerAccessToken
          address: $address
        ) {
          customerAddress {
            ...addressFragment
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
