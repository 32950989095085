import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

export const removeCustomerAddress = async (
  variables: Shopify.CustomerAddressDeleteMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAddressDeleteMutation,
    Shopify.CustomerAddressDeleteMutationVariables
  >({
    mutation: gql`
      mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
        customerAddressDelete(
          id: $id
          customerAccessToken: $customerAccessToken
        ) {
          customerUserErrors {
            code
            field
            message
          }
          deletedCustomerAddressId
        }
      }
    `,
    variables,
  })

  return result
}
