import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

import { CORE_CUSTOMER_FIELDS_FRAGMENT } from 'graph/shopify-storefront/customer'

export const updateCustomer = async (
  variables: Shopify.CustomerUpdateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerUpdateMutation,
    Shopify.CustomerUpdateMutationVariables
  >({
    mutation: gql`
      ${CORE_CUSTOMER_FIELDS_FRAGMENT}

      mutation customerUpdate(
        $customerAccessToken: String!
        $customer: CustomerUpdateInput!
      ) {
        customerUpdate(
          customerAccessToken: $customerAccessToken
          customer: $customer
        ) {
          customer {
            ...CoreCustomerFieldsFragment
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
