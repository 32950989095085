import { entries } from '@cellulargoods/core'
import { Shopify, Sanity } from '@cellulargoods/types'
import { replaceLineItems } from 'services/cart/replaceLineItems'

/**
 * Returns the ProductId from an Order Line Item.
 * We use this alongside getSanityProductMediaImage.
 */
export const getProductIdFromOrderLineItem = (
  lineItem: Shopify.OrderLineItem | Shopify.CheckoutLineItemFragment
) => {
  console.log(lineItem?.variant?.product?.id)
  return lineItem?.variant?.product?.id.includes('gid://')
    ? lineItem?.variant?.product?.id.split('gid://shopify/Product/')[1]
    : lineItem?.variant?.product?.id
}

/**
 * Maps through the edges and nodes to return
 * the object.
 */
export function getObjFromEdges<
  T extends
    | Shopify.CheckoutLineItem
    | Shopify.MailingAddress
    | Shopify.Metafield
    | Shopify.Order
    | Shopify.OrderLineItem
>(
  connection?:
    | Shopify.CheckoutLineItemConnection
    | Shopify.MailingAddressConnection
    | Shopify.MetafieldConnection
    | Shopify.OrderConnection
    | Shopify.OrderLineItemConnection
    | null
): T[] {
  return connection ? connection.edges.map((edge) => edge.node as T) : []
}

export const getPrimaryCtaCopy = (
  productStatus: Sanity.ProductStatus,
  soldout = false,
  isPDP = false
) => {
  if (productStatus === Sanity.ProductStatus.INTEREST) {
    return 'Register Interest'
  }

  if (soldout) {
    return 'Notify me when back in stock'
  }

  return isPDP ? 'Add to Bag' : 'Shop Now'
}

export const getLineItemInputs = (
  items: Shopify.CheckoutLineItemFragment[] = []
) => {
  // Get line items from current checkout
  // - reduce as CheckoutLineItemInputs
  const lineItemInputs = items.reduce((acc, val) => {
    if (val.variant) {
      acc = [
        ...acc,
        {
          customAttributes: val?.customAttributes?.map((attribute) => ({
            key: String(attribute.key),
            value: String(attribute.value),
          })),
          quantity: val.quantity,
          variantId: val.variant.id,
        },
      ]
    }
    return acc
  }, [] as Shopify.CheckoutLineItemInput[])

  return lineItemInputs
}

export const getDefaultAddressAsArray = (
  addresses: Shopify.MailingAddress[],
  defaultId: string
) => {
  if (addresses.length === 0) {
    return []
  }

  let [defaultAddress] = addresses.filter((add) => add.id === defaultId)

  if (!defaultAddress) {
    defaultAddress = addresses[0]
  }

  const addressArray = entries(defaultAddress).reduce((acc, curr) => {
    if (!curr) {
      return acc
    }

    if (curr[1] !== undefined && curr[1] !== null) {
      switch (curr[0]) {
        case 'address1':
        case 'address2':
        case 'city':
        case 'country':
        case 'zip':
          acc.push(curr[1])
          break
      }
    }

    return acc
  }, [] as string[])

  return addressArray
}

/**
 *  creating a checkout object with values that are not going to be available any more (soldout or delisted product)
 */
export const pruneCheckoutForSoldoutItems = async (
  checkout: Shopify.Checkout
): Promise<Shopify.Checkout> => {
  const availableLineItemInputs = getLineItemInputs(
    checkout?.lineItems?.edges
      ?.map((edge) => edge.node)
      .filter((item) => item.variant?.availableForSale)
  )

  const checkoutId = checkout.id

  const result = await replaceLineItems({
    checkoutId,
    lineItems: availableLineItemInputs,
  })

  return result.data?.checkoutLineItemsReplace?.checkout as Shopify.Checkout
}
