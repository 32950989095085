import { Shopify } from '@cellulargoods/types'
import { initializeApollo } from 'apollo/client'
import { CHECKOUT_FRAGMENT } from 'graph/shopify-storefront/checkout'
import gql from 'graphql-tag'

const updateCheckoutAttributes = async ({
  checkoutId,
  customAttributes,
}: {
  checkoutId: string
  customAttributes: Shopify.AttributeInput[]
}) => {
  const apolloClient = initializeApollo()

  const result =
    await apolloClient.mutate<Shopify.ShopifyCheckoutCreateMutation>({
      mutation: gql`
        ${CHECKOUT_FRAGMENT}
        mutation ShopifyCheckoutUpdateAttributes(
          $checkoutId: ID!
          $customAttributes: [AttributeInput!]
        ) {
          checkoutAttributesUpdateV2(
            checkoutId: $checkoutId
            input: { customAttributes: $customAttributes }
          ) {
            checkout {
              ...checkout
            }
          }
        }
      `,
      variables: {
        checkoutId,
        customAttributes,
      },
    })

  return result
}

export default updateCheckoutAttributes
