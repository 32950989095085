import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const renewCustomer = async (
  variables: Shopify.CustomerAccessTokenRenewMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAccessTokenRenewMutation,
    Shopify.CustomerAccessTokenRenewMutationVariables
  >({
    mutation: gql`
      mutation customerAccessTokenRenew($customerAccessToken: String!) {
        customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          userErrors {
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
