import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

import { CHECKOUT_FRAGMENT } from 'graph/shopify-storefront/checkout'

export const createCheckout = async ({
  currencyCode,
  customAttributes,
  lineItems,
}: {
  currencyCode: Shopify.CurrencyCode
  customAttributes?: Shopify.AttributeInput[]
  lineItems?: Shopify.CheckoutLineItemInput[]
}) => {
  const apolloClient = initializeApollo()

  const result =
    await apolloClient.mutate<Shopify.ShopifyCheckoutCreateMutation>({
      mutation: gql`
        ${CHECKOUT_FRAGMENT}
        mutation ShopifyCheckoutCreate(
          $currencyCode: CurrencyCode
          $customAttributes: [AttributeInput!]
          $lineItems: [CheckoutLineItemInput!]
        ) {
          checkoutCreate(
            input: {
              customAttributes: $customAttributes
              lineItems: $lineItems
              presentmentCurrencyCode: $currencyCode
            }
          ) {
            checkout {
              ...checkout
            }
          }
        }
      `,
      variables: {
        currencyCode,
        customAttributes,
        lineItems: lineItems || [],
      },
    })

  return result
}
