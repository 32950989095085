import { Shopify } from '@cellulargoods/types'

/**
 * Gets the current customer access token from localstorage.
 */
export const ACCESS_TOKEN_KEY = 'accessToken'

export const setCustomerAccessToken = (
  customerAccessToken: Shopify.CustomerAccessToken
): void => {
  if (typeof window !== 'undefined' && localStorage) {
    localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(customerAccessToken))
  } else {
    console.error('localStorage does not exist!')
  }
}

export const getCustomerAccessToken =
  (): Shopify.CustomerAccessToken | null => {
    if (typeof window !== 'undefined') {
      if (localStorage) {
        const accessTokenObject = localStorage.getItem(ACCESS_TOKEN_KEY)
        if (accessTokenObject) {
          return JSON.parse(accessTokenObject)
        }
      } else {
        console.error('localStorage does not exist!')
        return null
      }
    }
    return null
  }

export const revokeCustomerAccessToken = (): void => {
  if (typeof window !== 'undefined' && localStorage) {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  } else {
    console.error('localStorage does not exist!')
  }
}
