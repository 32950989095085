import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const createCustomer = async (
  variables: Shopify.CustomerCreateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerCreateMutation,
    Shopify.CustomerCreateMutationVariables
  >({
    mutation: gql`
      mutation customerCreate($input: CustomerCreateInput!) {
        customerCreate(input: $input) {
          customer {
            id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
