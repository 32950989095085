import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'
import { ADDRESS_FRAGMENT } from 'graph/shopify-storefront/address'

export const updateCustomerAddress = async (
  variables: Shopify.CustomerAddressUpdateMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAddressUpdateMutation,
    Shopify.CustomerAddressUpdateMutationVariables
  >({
    mutation: gql`
      ${ADDRESS_FRAGMENT}

      mutation customerAddressUpdate(
        $customerAccessToken: String!
        $id: ID!
        $address: MailingAddressInput!
      ) {
        customerAddressUpdate(
          customerAccessToken: $customerAccessToken
          id: $id
          address: $address
        ) {
          customerAddress {
            ...addressFragment
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
