import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './address'

export const CORE_CUSTOMER_FIELDS_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}

  fragment CoreCustomerFieldsFragment on Customer {
    id
    firstName
    lastName
    email
    phone
    acceptsMarketing
    honorific: metafield(namespace: "customer_metadata", key: "honorific") {
      value
      id
    }
    dob: metafield(namespace: "customer_metadata", key: "dob") {
      value
      id
    }
    defaultAddress {
      firstName
      lastName
      id
      address1
      address2
      city
      country
      zip
    }
    addresses(first: 10) {
      edges {
        node {
          ...addressFragment
        }
      }
    }
  }
`
