import { SetState, GetState } from 'zustand'
import { captureException } from '@sentry/nextjs'

import { createSanityClientRead } from '@cellulargoods/core'
import { Google, Shopify } from '@cellulargoods/types'

import { replaceLineItems } from 'services/cart/replaceLineItems'

import { getLineItemInputs } from 'helpers/shopify'
import { createCartEvent, pushToDataLayer } from 'helpers/gtm'

import type { CheckoutStateWithCartState } from './index'

export type LineItemUpdatePayload = {
  productVariantId: string
  quantity: number
  keepCartClose?: boolean
}

export const lineItemUpdate =
  <TState extends CheckoutStateWithCartState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async ({
    productVariantId,
    quantity,
    keepCartClose = false,
  }: LineItemUpdatePayload) => {
    set({
      checkoutUpdating: true,
      lineItemMutating: {
        status: true,
        id: productVariantId,
      },
    })

    // Get existing checkout from store
    const checkout = get().checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItemInputs = getLineItemInputs(
      checkout?.lineItems?.edges?.map((edge) => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId || !lineItemInputs) {
      const ERROR_MESSAGE = `Unable to retrieve checkout`
      console.error(ERROR_MESSAGE)
      captureException(ERROR_MESSAGE)
      return
    }

    // See if added variantID already exists in our checkout
    // If so, update quantities
    const lineItemIndex = lineItemInputs?.findIndex(
      (lineItem) => lineItem?.variantId === productVariantId
    )

    let currQuantity = 0

    if (lineItemIndex >= 0) {
      currQuantity = lineItemInputs[lineItemIndex].quantity
      lineItemInputs[lineItemIndex].quantity = quantity
    }

    const result = await replaceLineItems({
      checkoutId,
      lineItems: lineItemInputs,
    })

    if (result.errors) {
      const message = `Unable to update line items: ${result.errors
        .map((err) => err.message)
        .join(',')}`
      console.error(message)

      captureException(message)
      return
    }

    const updatedCheckout = result.data?.checkoutLineItemsReplace?.checkout

    const quantityDiff = quantity - currQuantity
    const event =
      quantityDiff <= 0
        ? Google.CartEvents.REMOVE_FROM_CART
        : Google.CartEvents.ADD_TO_CART

    // Get added line item
    const addedLineItem = updatedCheckout?.lineItems?.edges.find(
      (edge) => edge.node.variant?.id === productVariantId
    )?.node

    const client = createSanityClientRead()

    const productType = await client.fetch(
      /* groq */ `*[_type == "shopifyProduct" && idEncoded == $id][0].productType`,
      {
        id: addedLineItem?.variant?.product.id,
      }
    )

    const addToCartProductDetail = createCartEvent(
      event,
      {
        name: addedLineItem?.title ?? '',
        id: productVariantId,
        price: addedLineItem?.variant?.priceV2.amount ?? '',
        brand: 'Cellular Goods',
        category: productType, // can we add the category?
        variant: addedLineItem?.variant?.title ?? '',
      },
      quantityDiff
    )

    pushToDataLayer({ ecommerce: null })
    pushToDataLayer(addToCartProductDetail)

    set({
      checkout: updatedCheckout as Shopify.Checkout,
      lineItemMutating: {
        status: false,
        id: null,
      },
      cartIsOpen: !keepCartClose,
    })
  }
