import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

/**
 * This create the "forgot your password"
 * email that a user then uses in conjuction with
 * changeCustomerPassword
 */
export const recoverCustomer = async (
  variables: Shopify.CustomerRecoverMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerRecoverMutation,
    Shopify.CustomerRecoverMutationVariables
  >({
    mutation: gql`
      mutation customerRecover($email: String!) {
        customerRecover(email: $email) {
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
