import axios, { AxiosResponse } from 'axios'

import { entries } from '@cellulargoods/core'

import { SHOPIFY_METADATA } from 'graph/shopify-admin/admin'

export type CustomerMetafields = {
  honorific?: {
    value: string
    id?: string
  }
  dob?: {
    value: string
    id?: string
  }
}

const baseAxios = axios.create({
  baseURL: '/api/customer',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

type MetafieldUpdatePackage = {
  key: string
  value: string
  metafieldId?: string
}

export const updateCustomerMetafields = async (
  metafields: CustomerMetafields,
  accessToken: string
): Promise<boolean> => {
  const meta = entries(metafields)
    .map((entry) => {
      if (!entry) {
        return null
      }
      const [key, value] = entry

      if (!value) {
        return null
      }

      switch (key) {
        case 'honorific':
          return {
            value: value.value,
            key: SHOPIFY_METADATA.namespaces.customer_metadata.keys.honorific,
            metafieldId: value.id,
          }
        case 'dob':
          return {
            value: value.value,
            key: SHOPIFY_METADATA.namespaces.customer_metadata.keys.dob,
            metafieldId: value.id,
          }
      }
    })
    .filter((pkg) => Boolean(pkg)) as MetafieldUpdatePackage[]

  const result = await Promise.all(
    meta.map((pkg) => {
      return baseAxios.post<
        { success: boolean },
        AxiosResponse<{ success: boolean }>,
        MetafieldUpdatePackage & {
          customerAccessToken: string
        }
      >('/updateCustomerMetadata', {
        ...pkg,
        customerAccessToken: accessToken,
      })
    })
  )

  if (result.some((res) => !res.data.success)) {
    return false
  } else {
    return true
  }
}
