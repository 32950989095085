import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { resetCustomerPassword } from 'services/accounts/resetCustomerPassword'

import { STRINGS } from 'references/locale'
import { setCustomerAccessToken } from 'helpers/tokens'

export type ResetCustomerPasswordPayload =
  Shopify.CustomerResetByUrlMutationVariables

export type ResetCustomerPasswordReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
}

export const customerResetPassword =
  () =>
  async ({
    resetUrl,
    password,
  }: ResetCustomerPasswordPayload): Promise<ResetCustomerPasswordReturn> => {
    if (!resetUrl || !password) {
      console.error(STRINGS['customer.resetPassword.payload.missing'])
      return {
        success: false,
        error: STRINGS['customer.resetPassword.payload.missing'],
      }
    }

    const result = await resetCustomerPassword({
      password,
      resetUrl,
    })

    if (result.data?.customerResetByUrl?.customerAccessToken) {
      setCustomerAccessToken(
        result.data?.customerResetByUrl?.customerAccessToken
      )

      return {
        success: true,
      }
    } else if (result.data?.customerResetByUrl?.customerUserErrors) {
      return {
        success: false,
        customerErrors:
          result.data.customerResetByUrl.customerUserErrors.reduce(
            (acc, { field, message }) => {
              if (Array.isArray(field)) {
                const [_, fieldName] = field

                acc[fieldName] = message
              }

              return acc
            },
            {} as Record<string, string>
          ),
      }
    } else {
      const ERROR_MSG = STRINGS['customer.resetPassword.sentry']
      console.error(ERROR_MSG)
      captureException(
        ERROR_MSG,
        new Scope().setExtras({
          errors: result.errors,
          resetUrl,
        })
      )

      return {
        success: false,
        error: STRINGS['customer.resetPassword.form.failed'],
      }
    }
  }
