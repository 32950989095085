import { SetState } from 'zustand'

export type SearchState = {
  searchOverlayActive: boolean
  setSearchOverlayActive: (isActive: boolean) => void
}

export const createSearchState = <TState extends SearchState>(
  set: SetState<TState>
): SearchState => ({
  searchOverlayActive: false,
  setSearchOverlayActive: (bool) =>
    set({
      searchOverlayActive: bool,
    }),
})
