import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { Shopify } from '@cellulargoods/types'

export const logoutCustomer = async (
  variables: Shopify.CustomerAccessTokenDeleteMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<
    Shopify.CustomerAccessTokenDeleteMutation,
    Shopify.CustomerAccessTokenDeleteMutationVariables
  >({
    mutation: gql`
      mutation customerAccessTokenDelete($customerAccessToken: String!) {
        customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
          deletedAccessToken
          deletedCustomerAccessTokenId
          userErrors {
            field
            message
          }
        }
      }
    `,
    variables,
  })

  return result
}
