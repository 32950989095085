import { Shopify } from '@cellulargoods/types'
import { captureException } from '@sentry/nextjs'
import { GetState, SetState } from 'zustand'

import { STRINGS } from 'references/locale'
import { getCustomerAccessToken, setCustomerAccessToken } from 'helpers/tokens'

import { renewCustomer } from 'services/accounts/renewCustomer'
import { getCustomer } from 'services/customer/getCustomer'

import { CustomerStateWithAccountState } from './index'

export type CustomerGetReturn =
  Shopify.CoreCustomerFieldsFragmentFragment | null

export const customerGet =
  <TState extends CustomerStateWithAccountState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async (): Promise<CustomerGetReturn> => {
    const accessToken = getCustomerAccessToken()
    const logoutCustomer = get().logoutCustomer

    /**
     * If there's an access token try to renew it
     * then try and get the customer after replacing
     * the existing token with the renewed.
     */
    if (accessToken) {
      const renewResult = await renewCustomer({
        customerAccessToken: accessToken.accessToken,
      })

      if (renewResult.data?.customerAccessTokenRenew?.customerAccessToken) {
        // save new access token
        setCustomerAccessToken(
          renewResult.data.customerAccessTokenRenew.customerAccessToken
        )

        const customerResult = await getCustomer({
          customerAccessToken:
            renewResult.data.customerAccessTokenRenew.customerAccessToken
              .accessToken,
        })

        if (customerResult.data.customer) {
          set({
            customerLoggedIn: true,
            customer: customerResult.data.customer as Shopify.Customer,
          })

          return customerResult.data.customer as Shopify.Customer
        } else {
          /**
           * We failed to get the customer,
           * we should log the customer out
           * to be safe incase something
           * malicious has happened
           */
          await logoutCustomer()

          const ERROR_MSG = STRINGS['customer.get.sentry']
          console.error(ERROR_MSG)
          captureException(new Error(ERROR_MSG))

          return null
        }
      } else {
        /**
         * If it can't renew then it's expired and might as well be destroyed
         */
        await logoutCustomer()

        return null
      }
    }

    /**
     * If no token then the user isn't logged in so no worries.
     */
    return null
  }
