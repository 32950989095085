import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { recoverCustomer } from 'services/accounts/recoverCustomer'

import { STRINGS } from 'references/locale'

export type RecoverCustomerPayload = Shopify.CustomerRecoverMutationVariables

export type RecoverCustomerReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
}

export const customerRecover =
  () =>
  async ({ email }: RecoverCustomerPayload): Promise<RecoverCustomerReturn> => {
    if (!email) {
      console.error(STRINGS['customer.recover.payload.missing'])
      return {
        success: false,
        error: STRINGS['customer.recover.payload.missing'],
      }
    }

    const result = await recoverCustomer({
      email,
    })

    if (result.data) {
      return { success: true }
    } else {
      const ERROR_MSG = STRINGS['customer.recover.sentry']
      console.error(ERROR_MSG)
      captureException(
        ERROR_MSG,
        new Scope().setExtras({
          errors: result.errors,
          email,
        })
      )

      return {
        success: false,
        error: STRINGS['customer.recover.form.failed'],
      }
    }
  }
