import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'
import { SetState } from 'zustand'

import { loginCustomer } from 'services/accounts/loginCustomer'
import { getCustomer } from 'services/customer/getCustomer'

import { STRINGS } from 'references/locale'

import { setCustomerAccessToken } from 'helpers/tokens'

import type { AccountStateWithCustomerState } from './index'

export type LoginCustomerPayload = Shopify.CustomerAccessTokenCreateInput

export type LoginCustomerReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
}

export const customerLogin =
  <TState extends AccountStateWithCustomerState>(set: SetState<TState>) =>
  async ({
    email,
    password,
  }: LoginCustomerPayload): Promise<LoginCustomerReturn> => {
    if (!email || !password) {
      console.error(STRINGS['customer.login.payload.missing'])
      return {
        success: false,
        error: STRINGS['customer.login.payload.missing'],
      }
    }

    const result = await loginCustomer({
      input: {
        email,
        password,
      },
    })

    if (result.data?.customerAccessTokenCreate?.customerAccessToken) {
      setCustomerAccessToken(
        result.data.customerAccessTokenCreate.customerAccessToken
      )

      const customerResult = await getCustomer({
        customerAccessToken:
          result.data.customerAccessTokenCreate.customerAccessToken.accessToken,
      })

      if (!customerResult.data.customer) {
        console.error(STRINGS['customer.login.get-after-login.sentry'])
        captureException(STRINGS['customer.login.get-after-login.sentry'])
      }

      set({
        customer: (customerResult.data.customer as Shopify.Customer) ?? null,
        customerLoggedIn: true,
      })

      return { success: true }
    } else if (result.data?.customerAccessTokenCreate?.customerUserErrors) {
      let error: string | undefined = undefined

      const customerErrors =
        result.data.customerAccessTokenCreate.customerUserErrors.reduce(
          (acc, { field, message, code }) => {
            if (Array.isArray(field)) {
              const [_, fieldName] = field

              acc[fieldName] = message
            } else if (code === 'UNIDENTIFIED_CUSTOMER') {
              /**
               * edge case, with login you get a generic error with
               * the code UNIDENTIFIED_CUSTOMER if the pw & email combo
               * don't match
               */
              error = STRINGS['customer.login.unidentified']
            }

            return acc
          },
          {} as Record<string, string>
        )

      return {
        success: false,
        customerErrors: error ? undefined : customerErrors,
        error,
      }
    } else {
      const ERROR_MSG = STRINGS['customer.login.sentry']
      console.error(ERROR_MSG)
      captureException(
        ERROR_MSG,
        new Scope().setExtras({
          errors: result.errors,
          email,
        })
      )

      return {
        success: false,
        error: STRINGS['customer.login.form.failed'],
      }
    }
  }
