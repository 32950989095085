import { captureException } from '@sentry/nextjs'
import { GetState, SetState } from 'zustand'
import { decode } from 'shopify-gid'

import { Shopify, Google } from '@cellulargoods/types'
import { createSanityClientRead } from '@cellulargoods/core'

import { replaceLineItems } from 'services/cart/replaceLineItems'

import { getLineItemInputs } from 'helpers/shopify'
import {
  createCartEvent,
  createProductFieldObjectFromLineItem,
  pushToDataLayer,
} from 'helpers/gtm'

import type { CheckoutStateWithCartState } from './index'

export type LineItemAddPayload = {
  customAttributes?: Shopify.AttributeInput[]
  productVariantId: string // base 64 pls
  quantity: number
}

export const lineItemAdd =
  <TState extends CheckoutStateWithCartState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async ({
    customAttributes,
    productVariantId,
    quantity,
  }: LineItemAddPayload): Promise<void> => {
    productVariantId =
      'gid://shopify/ProductVariant/' + decode(productVariantId).id

    set({
      checkoutUpdating: true,
      lineItemMutating: {
        status: true,
        id: productVariantId,
      },
    })

    // Get existing checkout from store
    const checkout = get().checkout

    const checkoutId = checkout?.id

    // Get existing line items in cart (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map((edge) => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      const ERROR_MESSAGE = `Unable to retrieve checkout`
      console.error(ERROR_MESSAGE)
      captureException(ERROR_MESSAGE)
      return
    }

    // See if added variantID already exists in our checkout
    // If so, update quantities
    // If not, create a new checkout input item
    const lineItemIndex = lineItems?.findIndex(
      (lineItem) => lineItem?.variantId === productVariantId
    )

    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex].quantity += quantity
    } else {
      lineItems.push({
        customAttributes,
        quantity,
        variantId: productVariantId,
      })
    }

    const { data, errors } = await replaceLineItems({ checkoutId, lineItems })

    if (errors) {
      const message = `Unable to retrieve checkout: ${errors
        .map((err) => err.message)
        .join(',')}`
      console.error(message, checkoutId)

      captureException(message)
      return
    }

    const updatedCheckout = data?.checkoutLineItemsReplace?.checkout

    // Get added line item
    const addedLineItem = updatedCheckout?.lineItems?.edges.find(
      (edge) => edge.node.variant?.id === productVariantId
    )?.node

    const client = createSanityClientRead()

    const productType = await client.fetch<string>(
      /* groq */ `*[_type == "shopifyProduct" && idEncoded == $id][0].productType`,
      {
        id: addedLineItem?.variant?.product.id,
      }
    )

    const addToCartProductDetail = createCartEvent(
      Google.CartEvents.ADD_TO_CART,
      createProductFieldObjectFromLineItem(
        addedLineItem as Shopify.CheckoutLineItem,
        productType,
        productVariantId
      ),
      quantity
    )
    // @ts-ignore
    if (pintrk && updatedCheckout) {
      // @ts-ignore
      pintrk('track', 'addtocart', {
        order_id: updatedCheckout?.id,
        product_id: productVariantId,
        product_quantity: quantity,
        product_name:
          updatedCheckout.lineItems.edges.filter(
            (i) => i.node.variant?.id === productVariantId
          )[0]?.node?.title ?? undefined,
        value: updatedCheckout?.totalPriceV2.amount,
      })
    }

    pushToDataLayer({ ecommerce: null })
    pushToDataLayer(addToCartProductDetail)

    set({
      checkout: updatedCheckout as Shopify.Checkout,
      checkoutUpdating: false,
      lineItemMutating: {
        status: false,
        id: null,
      },
      cartIsOpen: true,
    })
  }
