import { gql } from '@apollo/client'

const CHECKOUT_LINE_ITEM_FRAGMENT = gql`
  fragment checkoutLineItem on CheckoutLineItem {
    customAttributes {
      key
      value
    }
    id
    title
    variant {
      id
      title
      image {
        transformedSrc(maxWidth: 400)
      }
      compareAtPriceV2 {
        amount
      }
      priceV2 {
        amount
      }
      availableForSale
      quantityAvailable
      product {
        id
      }
    }
    quantity
  }
`

export const CHECKOUT_FRAGMENT = gql`
  ${CHECKOUT_LINE_ITEM_FRAGMENT}
  fragment checkout on Checkout {
    completedAt
    currencyCode
    customAttributes {
      key
      value
    }
    id
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...checkoutLineItem
        }
      }
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    webUrl
  }
`
