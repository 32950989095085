import { useRouter } from 'next/router'

import { useStore, UseStore } from 'store'

type UseShopifyCustomer = Pick<
  UseStore,
  | 'createCustomer'
  | 'loginCustomer'
  | 'recoverCustomer'
  | 'resetCustomerPassword'
  | 'customer'
  | 'getCustomer'
  | 'updateCustomer'
> & {
  logoutCustomer: () => void
  isLoggedIn: boolean
}

export const useShopifyCustomer = (): UseShopifyCustomer => {
  const router = useRouter()

  const createCustomer = useStore((state) => state.createCustomer)
  const loginCustomer = useStore((state) => state.loginCustomer)
  const customerLogout = useStore((state) => state.logoutCustomer)
  const isLoggedIn = useStore((state) => state.customerLoggedIn)

  const recoverCustomer = useStore((state) => state.recoverCustomer)

  const resetCustomerPassword = useStore((state) => state.resetCustomerPassword)

  const customer = useStore((state) => state.customer)
  const getCustomer = useStore((state) => state.getCustomer)

  const updateCustomer = useStore((state) => state.updateCustomer)

  const logoutCustomer = async () => {
    await customerLogout()
    router.replace('/')
  }

  return {
    createCustomer,
    loginCustomer,
    logoutCustomer,
    recoverCustomer,
    isLoggedIn,
    resetCustomerPassword,
    customer,
    getCustomer,
    updateCustomer,
  }
}
