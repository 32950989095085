import gql from 'graphql-tag'

import { initializeApollo } from 'apollo/client'

import { CHECKOUT_FRAGMENT } from 'graph/shopify-storefront/checkout'

export const applyDiscountCode = async (variables: any) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<any>({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutDiscountCodeApply(
        $checkoutId: ID!
        $discountCode: String!
      ) {
        checkoutDiscountCodeApplyV2(
          checkoutId: $checkoutId
          discountCode: $discountCode
        ) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables,
  })

  return result
}

export const removeDiscountCodes = async (variables: any) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<any>({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutDiscountCodeRemove($checkoutId: ID!) {
        checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables,
  })

  return result
}
