import { GetState, SetState } from 'zustand'
import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { STRINGS } from 'references/locale'
import { getCustomerAccessToken } from 'helpers/tokens'

import { updateCustomerAddress } from 'services/customer/updateCustomerAddress'
import { updateDefaultCustomerAddress } from 'services/customer/updateDefaultCustomerAddress'

import { CustomerStateWithAccountState } from './index'

export type UpdateCustomerAddressPayload = Pick<
  Shopify.CustomerAddressUpdateMutationVariables,
  'address' | 'id'
>

export type UpdateCustomerAddressReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
}

export const customerAddressUpdate =
  <TState extends CustomerStateWithAccountState>(
    _: SetState<TState>,
    get: GetState<TState>
  ) =>
  async (
    { address, id }: UpdateCustomerAddressPayload,
    shouldBeDefault = false
  ): Promise<UpdateCustomerAddressReturn> => {
    const getCustomer = get().getCustomer

    if (
      !address.firstName ||
      !address.lastName ||
      !address.address1 ||
      !address.city ||
      !address.zip ||
      !address.country
    ) {
      console.error(STRINGS['accounts.address.update.payload.missing'])
      return {
        success: false,
        error: STRINGS['accounts.address.update.payload.missing'],
      }
    }

    const accessToken = getCustomerAccessToken()

    /**
     * If there's an access token try to renew it
     * then try and get the customer after replacing
     * the existing token with the renewed.
     */
    if (accessToken) {
      const result = await updateCustomerAddress({
        customerAccessToken: accessToken.accessToken,
        address,
        id,
      })

      if (result.data?.customerAddressUpdate?.customerAddress) {
        if (shouldBeDefault) {
          await updateDefaultCustomerAddress({
            customerAccessToken: accessToken.accessToken,
            addressId: result.data.customerAddressUpdate.customerAddress.id,
          })
        }

        await getCustomer()

        return {
          success: true,
        }
      } else if (result.data?.customerAddressUpdate?.customerUserErrors) {
        return {
          success: false,
          customerErrors:
            result.data.customerAddressUpdate.customerUserErrors.reduce(
              (acc, { field, message }) => {
                if (Array.isArray(field)) {
                  const [_, fieldName] = field

                  acc[fieldName] = message
                }

                return acc
              },
              {} as Record<string, string>
            ),
        }
      } else {
        const ERR_MSG = STRINGS['accounts.address.update.sentry']
        console.error(ERR_MSG)
        captureException(ERR_MSG, () =>
          new Scope().setExtras({
            errors: result.errors,
            id,
          })
        )

        return {
          success: false,
          error: STRINGS['accounts.address.update.form.fail'],
        }
      }
    }

    /**
     * If no token then the user isn't logged in so no worries.
     */
    return { success: false }
  }
