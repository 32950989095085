import { GetState, SetState } from 'zustand'
import * as Sentry from '@sentry/nextjs'
import cookies from 'js-cookie'

import { Shopify } from '@cellulargoods/types'

import { CHECKOUT_COOKIE_ID } from 'references/constants'

import { getCheckout } from 'services/cart/getCheckout'
import { createCheckout } from 'services/cart/createCheckout'

import type { CheckoutState } from './index'
import { pruneCheckoutForSoldoutItems } from 'helpers/shopify'
import { removeDiscountCodes } from 'services/cart/applyDiscountCode'
export const checkoutGet =
  <TState extends CheckoutState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async () => {
    await get().getGwp()

    // Get checkout ID cookie
    const existingCheckoutId = cookies.get(CHECKOUT_COOKIE_ID)

    let existingCheckout: Shopify.Checkout
    if (existingCheckoutId) {
      const result = await getCheckout(existingCheckoutId)
      existingCheckout = result?.data?.node as Shopify.Checkout

      // Existing (non-completed) checkout found
      if (existingCheckout && !existingCheckout?.completedAt) {
        // getting a pruned checkout...

        const check = await removeDiscountCodes({
          checkoutId: existingCheckout?.id,
        })

        const updatedCheckout = check.data.checkoutDiscountCodeRemove
          .checkout as Shopify.Checkout

        updatedCheckout.lineItems.edges =
          updatedCheckout.lineItems.edges.filter((i) => {
            return !(
              i.node.customAttributes.findIndex(
                (x) => x.value === 'Gift with Purchase'
              ) >= 0
            )
          })

        const prunedCheckout = await pruneCheckoutForSoldoutItems(
          updatedCheckout as any
        )

        // turning existing -> pruned

        set({ checkout: prunedCheckout as Shopify.Checkout, loading: false })

        return
      }
    }

    // Get detected user currency from store, use default (store primary) currency if none found
    const detectedCurrency = Shopify.CurrencyCode.Gbp

    // Create new checkout
    const { data } = await createCheckout({
      currencyCode: detectedCurrency,
    })

    const newCheckout = data?.checkoutCreate?.checkout

    if (newCheckout) {
      // Store checkout id cookie
      cookies.set(CHECKOUT_COOKIE_ID, newCheckout.id, {
        expires: 1000 * 60 * 60 * 24 * 7, // 7 days
      })

      set({ checkout: newCheckout as Shopify.Checkout })
    } else {
      const ERROR_MSG = 'Unable to create new checkout'
      console.error(ERROR_MSG)
      Sentry.captureException(new Error(ERROR_MSG))
    }
  }
