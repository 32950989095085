import { GetState, SetState } from 'zustand'
import { encode } from 'shopify-gid'
import { Shopify } from '@cellulargoods/types'
import { parseGid } from '@shopify/admin-graphql-api-utilities'

import { checkoutGet } from './getCheckout'
import { checkoutProceed } from './proceedCheckout'
import { lineItemAdd, LineItemAddPayload } from './lineItemAdd'
import { lineItemRemove, LineItemRemovePayload } from './lineItemRemove'
import { lineItemUpdate, LineItemUpdatePayload } from './lineItemUpdate'
import { LocalCartState } from '../localCart'
import { createSanityClientRead } from '@cellulargoods/core'
import {
  applyDiscountCode,
  removeDiscountCodes,
} from 'services/cart/applyDiscountCode'
import { getLineItemInputs } from 'helpers/shopify'
import { replaceLineItems } from 'services/cart/replaceLineItems'

export type CheckoutStateWithCartState = CheckoutState & LocalCartState

export type CheckoutState = {
  checkout?: Shopify.Checkout
  checkoutUpdating: boolean
  lineItemMutating: {
    status: boolean
    id: string | null
  }
  loading: boolean
  validatingGwp: boolean
  gwp: {
    offer: 'spend' | 'cartQuantity' | 'tiered'
    promocode: string
    productQuantity?: number
    title?: string
    qualifyingProducts: { id: number; variantId: number }[]
    autoaddProducts: { id: number; variantId: number }[]
    spendThreshold?: number
    tiers?: {
      autoaddProducts: { id: number; variantId: number }[]
      spendThreshold: number
      title?: string
      promocode: string
    }[]
  }[]
  getGwp: () => Promise<void>
  validateGwp: () => Promise<void>
  getCheckout: () => Promise<void>
  findEligibleOffers: () => {
    offer: 'spend' | 'cartQuantity' | 'tiered'
    promocode: string
    productQuantity?: number
    qualifyingProducts?: { id: number; variantId: number }[]
    autoaddProducts: { id: number; variantId: number }[]
    spendThreshold?: number
  }[]
  lineItemAdd: (payload: LineItemAddPayload) => Promise<void>
  lineItemRemove: (payload: LineItemRemovePayload) => Promise<void>
  lineItemUpdate: (payload: LineItemUpdatePayload) => Promise<void>
  proceedCheckout: () => Promise<string>
}

const getGwp =
  <TState extends CheckoutStateWithCartState>(
    set: SetState<TState>,
    _get: GetState<TState>
  ) =>
  async () => {
    const client = createSanityClientRead()

    const gwpOffers = await client.fetch<
      []
    >(/* groq */ `*[_type == "cart"][0].gwp[] {
  "offer": offertype,
  productQuantity,
  promocode,
  title,
  "qualifyingProducts": products[]->product->{
    id,
"variantId": variants[][0].id
  },
  "autoaddProducts": autoaddProducts[]->product->{
    id,
"variantId": variants[][0].id
  },
  spendThreshold,
  tiers[]{
    "autoaddProducts": autoaddProducts[]->product->{
       id,
   "variantId": variants[][0].id
     },
     title,
     spendThreshold,
     promocode
   }
}`)

    set({ gwp: gwpOffers })
  }

const findEligibleOffers =
  <TState extends CheckoutStateWithCartState>(
    _set: SetState<TState>,
    get: GetState<TState>
  ) =>
  () => {
    let eligibleOffers: {
      offer: 'spend' | 'cartQuantity' | 'tiered'
      title?: string
      productQuantity?: number | undefined
      promocode: string
      qualifyingProducts?: {
        id: number
        variantId: number
      }[]
      autoaddProducts: {
        id: number
        variantId: number
      }[]
      spendThreshold?: number | undefined
    }[] = []

    const checkout = get().checkout
    const offers = get().gwp

    offers?.map((offer) => {
      if (offer.offer === 'spend') {
        if (
          checkout?.totalPriceV2 &&
          offer.spendThreshold &&
          checkout.totalPriceV2.amount >= offer.spendThreshold / 100
        ) {
          eligibleOffers = [...eligibleOffers, offer]
        }
      }

      if (offer.offer === 'tiered') {
        if (checkout?.totalPriceV2 && offer.tiers) {
          const checkoutTotal = checkout.totalPriceV2
          let availableTierIndex: number | undefined

          offer.tiers
            ?.sort((a, b) => a.spendThreshold - b.spendThreshold)
            .map((tier, index) => {
              if (checkoutTotal.amount >= tier.spendThreshold / 100) {
                availableTierIndex = index
              }
            })

          if (availableTierIndex !== undefined) {
            eligibleOffers = [
              ...eligibleOffers,
              {
                ...offer.tiers[availableTierIndex],
                offer: 'tiered',
                title:
                  offer.tiers[availableTierIndex].title ??
                  offer.title ??
                  'Gift with purchase',
              },
            ]
          }
        }
      }

      if (
        offer.offer === 'cartQuantity' &&
        offer.qualifyingProducts &&
        offer.productQuantity
      ) {
        const cartLineItems =
          checkout?.lineItems?.edges?.map((i) => ({
            quantity: i.node.quantity,
            id: i.node.variant?.id,
          })) ?? []
        const qualifyingProductIds = offer.qualifyingProducts.map((i) => {
          return i.variantId
        })
        const filtered = cartLineItems.filter((i) => {
          return qualifyingProductIds.includes(parseInt(parseGid(i.id + '')))
        })
        const count = filtered.reduce((acc, val) => (acc += val.quantity!), 0)
        if (count >= offer.productQuantity) {
          eligibleOffers = [...eligibleOffers, offer]
        }
      }
    })
    return eligibleOffers
  }

const validateGwp =
  <TState extends CheckoutStateWithCartState>(
    set: SetState<TState>,
    get: GetState<TState>
  ) =>
  async () => {
    set({ validatingGwp: true })
    const eligibleOffers = get().findEligibleOffers()
    const checkout = get().checkout

    let lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map((edge) => edge.node)
    )

    const promocodes: string[] = []

    if (eligibleOffers.length > 0) {
      // Check if the product is in the cart
      await Promise.all(
        eligibleOffers.map(async (eligibleOffer) => {
          const encoded = encode(
            'ProductVariant',
            eligibleOffer.autoaddProducts[0].variantId
          )
          const exists = checkout?.lineItems.edges.findIndex(
            (i) => i?.node?.variant?.id === encoded
          )
          if (!exists || exists < 0) {
            lineItems.push({
              variantId: encoded,
              quantity: 1,
              customAttributes: [{ key: 'Promo', value: 'Gift with Purchase' }],
            })
          } else {
            if (lineItems[exists].quantity === 1) {
              lineItems[exists].customAttributes = [
                { key: 'Promo', value: 'Gift with Purchase' },
              ]
            } else {
              const another = { ...lineItems[exists] }
              lineItems[exists].quantity = lineItems[exists].quantity - 1
              lineItems.push({
                ...another,
                quantity: 1,
                customAttributes: [
                  { key: 'Promo', value: 'Gift with Purchase' },
                ],
              })
            }
          }

          promocodes.push(eligibleOffer.promocode)
        })
      )
    } else {
      lineItems = lineItems?.filter((i) => {
        return (
          i.customAttributes?.findIndex(
            (x) => x.value === 'Gift with Purchase'
          ) ?? -1 >= 0
        )
      })
    }

    let finalCheckout: any

    await replaceLineItems({ checkoutId: checkout!.id, lineItems })

    if (promocodes.length > 0) {
      const { data } = await applyDiscountCode({
        checkoutId: checkout?.id,
        discountCode: promocodes[0],
      })

      finalCheckout = data!.checkoutDiscountCodeApplyV2!.checkout
    } else {
      const { data } = await removeDiscountCodes({ checkoutId: checkout?.id })
      finalCheckout = data!.checkoutDiscountCodeRemove!.checkout
    }

    set({ validatingGwp: false, checkout: finalCheckout as Shopify.Checkout })

    // await  get().lineItemAdd({ productVariantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDc3ODY2OTM5MjAzMw==", quantity: 1, })
  }

export const createCartState = <TState extends CheckoutStateWithCartState>(
  set: SetState<TState>,
  get: GetState<TState>
): CheckoutState => ({
  getGwp: getGwp(set, get),
  findEligibleOffers: findEligibleOffers(set, get),
  validateGwp: validateGwp(set, get),
  getCheckout: checkoutGet(set, get),
  lineItemAdd: lineItemAdd(set, get),
  lineItemRemove: lineItemRemove(set, get),
  lineItemUpdate: lineItemUpdate(set, get),
  proceedCheckout: checkoutProceed(set, get),
  loading: true,
  checkoutUpdating: false,
  lineItemMutating: {
    status: false,
    id: null,
  },
  gwp: [],
  validatingGwp: false,
})
