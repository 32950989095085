import { SetState, GetState } from 'zustand'
import { captureException } from '@sentry/nextjs'
import { decode } from 'shopify-gid'

import {
  createSanityClientRead,
  ensurePriceHasDecimals,
} from '@cellulargoods/core'

import {
  createCheckoutEvent,
  createProductFieldObjectFromLineItem,
  getGoogleClientId,
  pushToDataLayer,
} from 'helpers/gtm'

import type { CheckoutState } from './index'
import updateCheckoutAttributes from 'services/cart/updateAttributes'
import { getCustomerAccessToken } from 'helpers/tokens'
import { initializeApollo } from 'apollo/client'
import { gql } from '@apollo/client'

export const checkoutProceed =
  <TState extends CheckoutState>(_: SetState<TState>, get: GetState<TState>) =>
  async (): Promise<string> => {
    // Get existing checkout from store
    const checkout = get().checkout
    const checkoutId = checkout?.id

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      const ERROR_MESSAGE = `Unable to retrieve checkout`
      console.error(ERROR_MESSAGE)
      captureException(ERROR_MESSAGE)
      return ''
    }

    // Determine GA client ID
    const gaClientId = await getGoogleClientId()
    const token = getCustomerAccessToken()

    if (token) {
      const apolloClient = initializeApollo()

      await apolloClient.mutate({
        mutation: gql`
          mutation checkoutCustomerAssociateV2(
            $checkoutId: ID!
            $customerAccessToken: String!
          ) {
            checkoutCustomerAssociateV2(
              checkoutId: $checkoutId
              customerAccessToken: $customerAccessToken
            ) {
              checkout {
                id
              }
            }
          }
        `,
        variables: {
          checkoutId: checkout.id,
          customerAccessToken: token.accessToken,
        },
      })
    }

    // If we're able to obtain a GA client id: update checkout with custom attributes for littledata
    if (gaClientId) {
      await updateCheckoutAttributes({
        checkoutId,
        customAttributes: [
          {
            key: 'google-clientID',
            value: gaClientId,
          },
        ],
      })
    }

    await get().validateGwp()

    const lineItems = checkout?.lineItems?.edges?.map((edge) => edge.node)

    /**
     * create sanity read client
     */
    const client = createSanityClientRead()

    const productTypes = await Promise.all(
      lineItems.map(async (lineItem) => {
        const type = await client.fetch<string>(
          /* groq */ `*[_type == "shopifyProduct" && idEncoded == $id][0].productType`,
          {
            id: lineItem?.variant?.product.id,
          }
        )

        return type
      })
    )

    const checkoutEvent = createCheckoutEvent(
      lineItems.map((lineItem, i) => ({
        ...createProductFieldObjectFromLineItem(
          lineItem,
          productTypes[i] ?? '',
          decode(lineItem.id).id
        ),
        quantity: lineItem.quantity,
      })),
      {
        id: checkoutId ?? '',
        revenue:
          ensurePriceHasDecimals(checkout.totalPriceV2.amount, false) ?? '',
      }
    )

    pushToDataLayer({ ecommerce: null })
    pushToDataLayer(checkoutEvent)

    return checkout.webUrl
  }
