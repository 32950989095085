import gql from 'graphql-tag'

import { Shopify } from '@cellulargoods/types'

import { initializeApollo } from 'apollo/client'

import { CHECKOUT_FRAGMENT } from 'graph/shopify-storefront/checkout'

export const replaceLineItems = async (
  variables: Shopify.ShopifyCheckoutLineItemsReplaceMutationVariables
) => {
  const apolloClient = initializeApollo()

  const result =
    await apolloClient.mutate<Shopify.ShopifyCheckoutLineItemsReplaceMutation>({
      mutation: gql`
        ${CHECKOUT_FRAGMENT}
        mutation ShopifyCheckoutLineItemsReplace(
          $checkoutId: ID!
          $lineItems: [CheckoutLineItemInput!]!
        ) {
          checkoutLineItemsReplace(
            checkoutId: $checkoutId
            lineItems: $lineItems
          ) {
            checkout {
              ...checkout
            }
          }
        }
      `,
      variables,
    })

  return result
}
