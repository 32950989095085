import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { createCustomer } from 'services/accounts/createCustomer'

import { STRINGS } from 'references/locale'

export type CreateCustomerPayload = Shopify.CustomerCreateInput

export type CreateCustomerReturn = {
  success: boolean
  error?: string
  customerErrors?: Record<string, string>
  customerId?: string
}

export const customerCreate =
  () =>
  async ({
    email,
    password,
    ...restPayload
  }: CreateCustomerPayload): Promise<CreateCustomerReturn> => {
    if (!email || !password) {
      console.error(STRINGS['customer.create.payload.missing'])
      return {
        success: false,
        error: STRINGS['customer.create.payload.missing'],
      }
    }

    const result = await createCustomer({
      input: {
        email,
        password,
        ...restPayload,
      },
    })

    if (result.data?.customerCreate?.customer?.id) {
      return {
        success: true,
        customerId: result.data?.customerCreate?.customer?.id,
      }
    } else if (result.data?.customerCreate?.customerUserErrors) {
      return {
        success: false,
        customerErrors: result.data.customerCreate.customerUserErrors.reduce(
          (acc, { field, message }) => {
            if (Array.isArray(field)) {
              const [_, fieldName] = field

              acc[fieldName] = message
            }

            return acc
          },
          {} as Record<string, string>
        ),
      }
    } else {
      const ERROR_MSG = STRINGS['customer.create.sentry']
      console.error(ERROR_MSG)
      captureException(
        ERROR_MSG,
        new Scope().setExtras({
          errors: result.errors,
          email,
        })
      )

      return {
        success: false,
        error: STRINGS['customer.create.form.failed'],
      }
    }
  }
