export const DELETE_METAFIELDS_MUTATION = `
  mutation metafieldDelete($input: MetafieldDeleteInput!) {
    metafieldDelete(input: $input) {
      deletedId
      userErrors {
        field
        message
      }
    }
  }
`

export const SET_METAFIELDS_MUTATION = `
  mutation metafieldsSet($metafields: [MetafieldsSetInput!]!) {
    metafieldsSet(metafields: $metafields) {
      metafields {
        id
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export const SHOPIFY_METADATA = {
  namespaces: {
    customer_metadata: {
      namespace: 'customer_metadata',
      keys: {
        honorific: 'honorific',
        dob: 'dob',
      },
    },
  },
}
