import { ensurePriceHasDecimals } from '@cellulargoods/core'
import { Google, Sanity, Shopify } from '@cellulargoods/types'

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[]
    ga: any // GA
  }
}

export const pushToDataLayer = (item: Record<string, unknown>) => {
  if (window.dataLayer) {
    window.dataLayer.push(item)
  } else {
    console.warn(
      'No dataLayer found, unable to push objects. Check GTM configuration'
    )
  }
}

const createProductFieldObjectFromSanityProduct = (
  prod: Sanity.Product
): Google.ProductFieldObject => {
  const [activeVariant] = prod.product?.variants ?? []
  return {
    name: prod.product?.title ?? '',
    id: activeVariant.id?.toString() ?? '',
    price: ensurePriceHasDecimals(activeVariant.price ?? '', false),
    brand: 'Cellular Goods',
    category: prod.product?.productType ?? '',
    variant: activeVariant.title ?? '',
  }
}

export const createProductFieldObjectFromLineItem = (
  lineItem: Shopify.CheckoutLineItem,
  productType: string,
  id?: string
): Google.ProductFieldObject => {
  return {
    name: lineItem?.title ?? '',
    id: id ? id : lineItem?.variant?.product.id ?? '',
    price: ensurePriceHasDecimals(lineItem.variant?.priceV2.amount, false),
    brand: 'Cellular Goods',
    category: productType, // can we add the category?
    variant: lineItem?.variant?.title ?? '',
  }
}

export const createListingImpressions = (
  products: Sanity.Product[],
  list = 'All Products'
): Google.Impressions => ({
  ecommerce: {
    currencyCode: 'GBP',
    impressions: products.map((prod, i) => {
      return {
        ...createProductFieldObjectFromSanityProduct(prod),
        list,
        position: i,
      }
    }),
  },
})

export const createDetail = (product: Sanity.Product): Google.Detail => {
  return {
    ecommerce: {
      detail: {
        products: [createProductFieldObjectFromSanityProduct(product)],
      },
    },
  }
}

export const createCartEvent = (
  event: Google.CartEvents,
  product: Google.ProductFieldObject,
  quantity = 1
): Google.CartEvent => {
  if (event === Google.CartEvents.ADD_TO_CART) {
    return {
      event,
      ecommerce: {
        currencyCode: 'GBP',
        add: {
          products: [{ ...product, quantity }],
        },
      },
    }
  }

  return {
    event,
    ecommerce: {
      currencyCode: 'GBP',
      remove: {
        products: [{ ...product, quantity }],
      },
    },
  }
}

export const createCheckoutEvent = (
  products: Google.ProductFieldObject[],
  actionField: Google.CheckoutActionField
): Google.CheckoutEvent => {
  return {
    event: 'checkout',
    ecommerce: {
      currencyCode: 'GBP',
      checkout: {
        actionField,
        products,
      },
    },
  }
}

export const getGoogleClientId = (): Promise<string> => {
  return new Promise((resolve) => {
    // gtag only makes 'ga' function available after the library loads
    // so we have to stub it if undefined here
    window.ga =
      window.ga ||
      function () {
        ;(window.ga.q = window.ga.q || []).push(arguments) //eslint-disable-line
      }
    window.ga.l = +new Date()

    const fallback = window.setTimeout(function () {
      //after 4 seconds, assume the script is blocked
      resolve('')
    }, 4000)
    window.ga(function () {
      // this function is called after GA library initializes
      window.clearTimeout(fallback)
      const tracker = window.ga.getAll()[0]
      const clientId: string = tracker && tracker.get('clientId')

      return resolve(clientId)
    })
  })
}
