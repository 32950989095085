import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { STRINGS } from 'references/locale'
import { getCustomerAccessToken } from 'helpers/tokens'

import { getOrders } from 'services/customer/getOrders'

export type OrderGetReturn = null | Shopify.Order

export type OrderGetPayload = {
  orderId: string
}

export const orderGet =
  () =>
  async ({ orderId }: OrderGetPayload): Promise<OrderGetReturn> => {
    const accessToken = getCustomerAccessToken()

    /**
     * If there's an access token try to renew it
     * then try and get the customer after replacing
     * the existing token with the renewed.
     */
    if (accessToken) {
      const { data, errors } = await getOrders({
        customerAccessToken: accessToken.accessToken,
      })

      if (data.customer?.orders.edges) {
        const [order] = data.customer?.orders.edges
          .map((edge) => edge.node as Shopify.Order)
          .filter((order) => order.id === orderId)

        if (order) {
          return order
        } else {
          return null
        }
      } else {
        const ERR_MSG = STRINGS['customer.orders.sentry']
        console.error(ERR_MSG)
        captureException(ERR_MSG, () =>
          new Scope().setExtras({
            errors,
          })
        )
      }
    }

    /**
     * If no token then the user isn't logged in so no worries.
     */
    return null
  }
