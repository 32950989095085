import { GetState, SetState } from 'zustand'
import { Shopify } from '@cellulargoods/types'
import { captureException, Scope } from '@sentry/nextjs'

import { STRINGS } from 'references/locale'
import { getCustomerAccessToken } from 'helpers/tokens'

import { removeCustomerAddress } from 'services/customer/removeCustomerAddress'

import { CustomerStateWithAccountState } from './index'

export type DeleteCustomerAddressPayload = Pick<
  Shopify.CustomerAddressDeleteMutationVariables,
  'id'
>

export type DeleteCustomerAddressReturn = {
  success: boolean
  error?: string
}

export const customerAddressDelete =
  <TState extends CustomerStateWithAccountState>(
    _: SetState<TState>,
    get: GetState<TState>
  ) =>
  async ({
    id,
  }: DeleteCustomerAddressPayload): Promise<DeleteCustomerAddressReturn> => {
    const getCustomer = get().getCustomer

    const accessToken = getCustomerAccessToken()

    /**
     * If there's an access token try to renew it
     * then try and get the customer after replacing
     * the existing token with the renewed.
     */
    if (accessToken) {
      const result = await removeCustomerAddress({
        customerAccessToken: accessToken.accessToken,
        id,
      })

      if (result.data?.customerAddressDelete?.deletedCustomerAddressId) {
        await getCustomer()

        return {
          success: true,
        }
      } else {
        const ERR_MSG = STRINGS['accounts.address.delete.sentry']
        console.error(ERR_MSG)
        captureException(ERR_MSG, () =>
          new Scope().setExtras({
            errors: result.errors,
            id,
          })
        )

        return {
          success: false,
          error: STRINGS['accounts.address.delete.form.fail'],
        }
      }
    }

    /**
     * If no token then the user isn't logged in so no worries.
     */
    return { success: false }
  }
