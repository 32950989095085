import { gql } from '@apollo/client'

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on MailingAddress {
    id
    firstName
    lastName
    address1
    address2
    city
    country
    zip
  }
`
